module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.3.3","publicApiKey":"NDYxOGY2Y2MtYjY3MC00MmE1LTkyM2QtYWI5NmU0OWFlOTkwNjM3MTIyOTM2ODEzOTQ5NDkx","defaultLang":"en","currency":"usd","openCartOnAdd":true,"useSideCart":true,"templatesUrl":"https://cdn.snipcart.com/themes/v3.3.3/default/snipcart.css"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://headless-wordpress-snipcart.azurewebsites.net/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter WordPress Homepage","short_name":"Gatsby","start_url":"/","background_color":"#ffffff","theme_color":"#004ca3","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"955a9d05c5c141a98f792a6172f6a651"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
